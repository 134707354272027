import { Toaster } from "./components/ui/toaster";
import "./global.css";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <main>
      <AppRoutes />
      <Toaster />
    </main>
  );
}

export default App;
