import CardProgram from "@/components/card/CardProgram";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useFetchData } from "@/hooks/useFetchData";
import usePostData from "@/hooks/usePostData";
import useProgram from "@/programs/hooks/useProgram";
import { ProgramType } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FolderIcon } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useAuth } from "@/auth/hooks/useAuth";
import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import { File, Folder, Tree } from "@/components/extension/tree-view-api";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import useCheckIsDevice from "@/hooks/useDeviceType";
import { useTranslation } from "react-i18next";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { Link, useNavigate } from "react-router-dom";
const FormSchema = z.object({
  programName: z
    .string()
    .min(1, {
      message: "Le nom du programme est obligatoire.",
    })
    .max(120, { message: "120 caractères maximum" }),
  programDescription: z.string().optional(),
});

interface MyData {
  name: string;
  description: string;
}

const Programs: FC = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  const code = localStorage.getItem("code");

  const { toast } = useToast();
  const { data } = useFetchData<ProgramType[]>(
    `${import.meta.env.VITE_API_ENDPOINT}/programs`
  );
  const { postData, loading, error, response } = usePostData<MyData>();
  const [programs, setPrograms] = useState<ProgramType[]>([]);
  const [open, setOpen] = useState(false);
  const { updateProgramData } = useProgram();
  const isMobile = useCheckIsDevice("mobile");
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      programName: "",
      programDescription: "",
    },
  });

  const [nameChar, setNameChar] = useState(0);

  const {t} = useTranslation("submit", {
    keyPrefix: "program"
  })

  // Mise à jour des programmes lorsque les données sont récupérées
  useEffect(() => {
    if (data) {
      setPrograms(data);
    }
  }, [data]);

  // Mise à jour des programmes après création réussie
  useEffect(() => {
    if (response?.success && response.data) {
      setPrograms((prevPrograms) => [
        ...prevPrograms,
        response.data as ProgramType,
      ]);
      toast({
        title: t("Programme ajouté avec succès"),
      });
      updateProgramData("programName", response.data.name);
      updateProgramData("programDescription", response.data.description);
      updateProgramData("programId", response.data.id);

      // setRun(programs.length <= 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  function onSubmit(formData: z.infer<typeof FormSchema>) {
    postData({
      url: `${import.meta.env.VITE_API_ENDPOINT}/program/save`,
      data: {
        name: formData.programName,
        description: formData.programDescription || "",
      },
    });
    setOpen(false);
  }

  /* ---------------- Guide -------------- */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [run, setRun] = useState(false);
  const steps = [
    {
      target: "#addProgram",
      title: t("Ajout Programme"),
      content: (
        <div className="text-center">
          <p>
            {t("Vous n'avez pas encore de programme dans votre liste pour le moment, veuillez ajouter un programme en cliquant sur ajouter")}
          </p>
        </div>
      ),
      placement: "right",
      disableBeacon: true,
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
      setOpen(true);
    }
  };
  useEffect(() => {
    // setTimeout(() => {
    if (data === null || programs.length > 0) {
      setRun(false);
    } else if (data !== null && programs.length <= 0) {
      setRun(true);
    }
    // }, 300);
  }, [programs]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [currentStep, setCurrentStep] = useState<number>(() => {
    const stepLocalStorage = localStorage.getItem("step");
    return stepLocalStorage ? JSON.parse(stepLocalStorage) : 1;
  });
  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
    localStorage.setItem("step", JSON.stringify(step));
  };

  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: programs
        ?.map((item: any, key: number) => {
          return {
            id: (key + 2).toString(),
            isSelectable: true,
            name: item.name,
          };
        })
        .filter(Boolean),
    },
  ];

  if (code !== import.meta.env.VITE_API_CODE) {
    navigate(`/`);
    return <></>;
  }
  if ((!auth.isAuthenticated || !auth.user?.isVerified) && !auth.loading) {
    navigate(`/submit/login`);
    return <></>;
  }

  if (auth.loading) {
    return (
      <div className="container min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="mb-0">
        <ReactBreadcrumb
          items={[
            {
              href: "/submit/programs",
              name: t("Mes programmes"),
            },
          ]}
        />
      </div>
      <div className="flex w-full flex-wrap items-start">
        {!isMobile && (
          <div className="basis-full pr-4 md:basis-1/4 md:pt-4">
            <Tree
              className="overflow-hidden rounded-[20px] bg-[#f4f4f4] p-4"
              initialSelectedId={"1"}
              elements={elements}
            >
              <Folder element="Mes programmes" value="1">
                {programs?.map((item: any, key: number) => (
                  <File fileIcon="" value={(key + 2).toString()} key={key}>
                    <Link
                      to={`/submit/program/${item.id}`}
                      className="flex gap-2"
                    >
                      <FolderIcon className="flex size-5 opacity-70" />
                      <div className="flex-1">
                        <span
                          title={item.name}
                          className="line-clamp-1 text-left text-[13px] opacity-70"
                        >
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  </File>
                ))}
              </Folder>
            </Tree>
          </div>
        )}
        <div className="flex-1 py-4 md:px-4">
          <Joyride
            callback={handleJoyrideCallback}
            continuous={false}
            run={run}
            scrollToFirstStep={false}
            disableScrolling={true}
            showProgress={false}
            showSkipButton={false}
            steps={steps}
            styles={{
              options: {
                arrowColor: "#fff",
                backgroundColor: "#80B533",
                overlayColor: "rgba(255, 255, 255, 0.8)",
                spotlightShadow: "0 0 0 rgba(0, 0, 0, 0)",
                textColor: "#fff",
                zIndex: 1000,
              },
            }}
          />
          <h1 className="mb-3 text-xl font-medium">{t("Liste de mes programmes")}</h1>
          <p className="text-[#595959]">
            {t("Un programme est un type de dossier regroupant plusieurs projets.")}{" "}
            <strong>{t("La sous-division de ces programmes en projets")}</strong>{" "}
            {t("permet un suivi plus fin des indicateurs et une meilleure récupération des données.")}
          </p>
          {error && (
            <p className="mt-8" style={{ color: "red" }}>
              Error: {error}
            </p>
          )}
          {data !== null && (
            <div className={`-mx-4 mt-8 flex flex-wrap items-start`}>
              {programs?.map((program: ProgramType, key: number) => (
                <CardProgram
                  className="mb-4 w-1/2 min-w-52 flex-1 px-4 md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] "
                  key={key}
                  program={program}
                />
              ))}
              <div className="mb-4 w-1/2 min-w-52 flex-1 px-4 md:w-1/3 md:max-w-[33%] lg:w-1/3 lg:max-w-[33%] xl:w-1/4 xl:max-w-[25%] ">
                <div className="aspect-[255/200] w-full " id="addProgram">
                  <Dialog open={open} onOpenChange={setOpen}>
                    <DialogTrigger asChild>
                      <div className="flex size-full flex-col items-start justify-center">
                        <Button
                          variant="outline"
                          className=" flex size-full items-center justify-center rounded-[15px] border"
                          style={{ background: "#EFEFEF" }}
                        >
                          <div className="flex w-max  items-center">
                            <svg
                              width="48"
                              height="36"
                              viewBox="0 0 48 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.61257 36C3.34411 36 2.25863 35.5597 1.35609 34.6793C0.452031 33.7973 0 32.7375 0 31.5V4.5C0 3.2625 0.452031 2.20351 1.35609 1.323C2.25863 0.441 3.34411 0 4.61257 0H16.5476C17.1626 0 17.7492 0.1125 18.3073 0.3375C18.8639 0.5625 19.3536 0.881249 19.7765 1.29375L23.0628 4.5H41.5131C42.7815 4.5 43.8679 4.941 44.772 5.823C45.6744 6.70351 46.1257 7.7625 46.1257 9H21.1602L16.5476 4.5H4.61257V31.5L9.16747 16.7062C9.47498 15.7312 10.0423 14.9527 10.8695 14.3707C11.6952 13.7902 12.6077 13.5 13.6071 13.5H43.3581C44.9341 13.5 46.1742 14.109 47.0782 15.327C47.9806 16.5465 48.2205 17.8687 47.7976 19.2938L43.6463 32.7938C43.3389 33.7687 42.7723 34.5472 41.9467 35.1293C41.1195 35.7098 40.2063 36 39.2068 36H4.61257ZM9.45576 31.5H39.2068L43.3581 18H13.6071L9.45576 31.5Z"
                                fill="#D4D4D4"
                              />
                              <rect
                                x="25"
                                y="19"
                                width="3"
                                height="11"
                                rx="1"
                                fill="#D9D9D9"
                              />
                              <rect
                                x="32"
                                y="23"
                                width="3"
                                height="11"
                                rx="1"
                                transform="rotate(90 32 23)"
                                fill="#D9D9D9"
                              />
                            </svg>
                          </div>
                        </Button>
                        <div className="text-md text-foreground mt-4 flex cursor-pointer items-center gap-2 font-semibold opacity-70">
                        {t("Ajouter")}
                        </div>
                      </div>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[600px]">
                      <Form {...form}>
                        <form
                          onSubmit={form.handleSubmit(onSubmit)}
                          className="space-y-6"
                        >
                          <DialogHeader>
                            <DialogTitle className="text-2xl">
                              {t("Ajout de programme")}
                            </DialogTitle>
                            <DialogDescription>
                              {t("Remplissez les informations ci-dessous pour ajouter un nouveau programme à votre liste.")}
                            </DialogDescription>
                          </DialogHeader>

                          <FormField
                            control={form.control}
                            name="programName"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>
                                  {t("Nom du programme")}
                                  <span className="text-destructive">*</span>
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    onKeyUp={(e) => setNameChar(e.currentTarget.value.length)}
                                    placeholder={t("Nom du programme")}
                                    {...field}
                                  />
                                </FormControl>
                                <div className="flex w-full">
                                  <div className="w-3/6">
                                    <FormMessage />
                                  </div>
                                  <FormDescription className="w-3/6 text-right">
                                    {nameChar > 120 ? 120 : nameChar} / 120
                                    {t("caractères")}
                                  </FormDescription>
                                </div>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="programDescription"
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>{t("Description du programme")} </FormLabel>
                                <FormControl>
                                  <Textarea
                                    placeholder={t("Description du programme")}
                                    {...field}
                                    className="w-full"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <DialogFooter>
                            <Button type="submit" disabled={loading}>
                              {loading ? "..." : t("Sauvegarder")}
                            </Button>
                          </DialogFooter>
                        </form>
                      </Form>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </div>
          )}
          {data === null && (
            <div className="mt-8">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Programs;
