import usePostData from "@/hooks/usePostData";
import { ProjectType } from "@/types";
export function useDatabase() {
  const { postData } = usePostData<any>();

  const updateProjectInDB = async (
    data,
    id?: number | null
  ): Promise<ProjectType> => {
    let url = "";
    if (id) {
      url = `${import.meta.env.VITE_IP_API}/api/project/edit/${id}`;
    } else {
      url = `${import.meta.env.VITE_IP_API}/api/project/save`;
    }

    const project = await postData({
      url,
      data,
    });
    return project;
  };

  const updateProgramInDB = async (
    data,
    id?: number | null
  ): Promise<ProjectType> => {
    let url = "";
    if (id) {
      url = `${import.meta.env.VITE_IP_API}/api/program/edit/${id}`;
    } else {
      url = `${import.meta.env.VITE_IP_API}/api/program/save`;
    }

    const program = await postData({
      url,
      data,
    });
    return program;
  };
  return { updateProgramInDB, updateProjectInDB };
}
