import CodeResetPasswordForm from "@/auth/components/CodeResetPasswordForm";
import ResetPasswordForm from "@/auth/components/ResetPasswordForm";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const [isSendingCode, setisSendingCode] = useState(false);
  const [searchParams] = useSearchParams();
  const mail = searchParams.get("mail");
  const code = searchParams.get("code");
  useEffect(() => {
    if (mail && code) {
      setisSendingCode(true);
    }
  }, [isSendingCode, mail, code]);
  return (
    <div className="container">
      {isSendingCode ? <ResetPasswordForm /> : <CodeResetPasswordForm />}
    </div>
  );
};

export default ResetPassword;
