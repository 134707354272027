import { useAuth } from "@/auth/hooks/useAuth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Bell,
  ChevronLeft,
  ChevronRight,
  FolderOpen,
  LayoutDashboard,
  MessagesSquare,
  Power,
  User2,
} from "lucide-react";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type LeftbarProps = {
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
};
const Leftbar: FC<LeftbarProps> = ({ setIsOpen, isOpen }) => {
  const { isAuthenticated, logout } = useAuth();
  return (
    <div
      className={`bg-secondary h-screen pt-8 flex flex-col pb-14 ${
        isOpen ? "pl-11 pr-4" : "pl-2 flex flex-col items-center pr-2"
      } rounded-r-[40px] bg-[url('/leftbar-bg.jpg')] bg-cover bg-center`}
    >
      <Link to="/dashboard">
        {isOpen ? (
          <img
            src="/logo-white.svg"
            alt="logo verdeo"
            width={90}
            className="mb-7"
          />
        ) : (
          <img
            src="/logo-white-min.svg"
            alt="logo verdeo"
            width={28}
            className="mb-7"
          />
        )}
      </Link>

      <ul>
        <LinkNav isOpen={isOpen} picto={<LayoutDashboard />} link="/">
          Dashboard
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<FolderOpen />} link="/">
          Parcelles
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<FolderOpen />} link="/">
          Projets
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<FolderOpen />} link="/">
          Actions
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<User2 />} link="/">
          Profil
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<Bell />} link="/">
          Notification
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<MessagesSquare />} link="/">
          Message
        </LinkNav>
        <LinkNav isOpen={isOpen} picto={<Bell />} link="/">
          Paramètres
        </LinkNav>
      </ul>
      <div
        className={`flex flex-wrap mt-auto ${
          isOpen ? "justify-between" : "justify-center"
        } items-center transition-all`}
      >
        {!isAuthenticated ? (
          <Link
            to="/submit/login"
            className="bg-background border-2 border-foreground rounded-[4rem] py-2 px-4 hover:bg-foreground hover:text-background transition-all"
          >
            Connexion
          </Link>
        ) : (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <div className="text-background flex items-center cursor-pointer gap-2 hover:text-destructive transition-all">
                <Power /> {isOpen && "Deconnexion"}
              </div>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Êtes-vous sûr de vouloir vous déconnecter ?
                </AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Annuler</AlertDialogCancel>
                <AlertDialogAction onClick={() => logout()}>
                  Se déconnecter
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}

        <button
          className="w-10 h-10 flex items-center justify-center text-background"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </button>
      </div>
    </div>
  );
};

const LinkNav = ({
  isOpen,
  link,
  picto,
  children,
}: {
  isOpen: boolean;
  link: string;
  picto: ReactNode;
  children: ReactNode;
}) => {
  return (
    <li className="mb-5">
      <Link
        to={`/dashboard${link}`}
        className="flex flex-wrap items-center gap-2 text-background hover:text-green-500 text-sm"
        title={children?.toString()}
      >
        {picto} {isOpen && children}
      </Link>
    </li>
  );
};
export default Leftbar;
