import { useEffect, useState } from "react";

const useCheckIsDevice = (
  device: "mobile" | "tablet" | "desktop" | "tablet and mobile"
) => {
  const [isDevice, setIsDevice] = useState<boolean>(false);

  useEffect(() => {
    const checkDevice = () => {
      const width = window.innerWidth;
      if (device === "mobile") {
        setIsDevice(width <= 768); // Définir la largeur max pour mobile
      } else if (device === "tablet") {
        setIsDevice(width > 768 && width <= 1024); // Définir la largeur pour tablette
      } else if (device === "tablet and mobile") {
        setIsDevice(width <= 1024);
      } else if (device === "desktop") {
        setIsDevice(width >= 1025);
      }
    };

    checkDevice(); // Vérifiez à l'initialisation

    window.addEventListener("resize", checkDevice); // Écoutez les changements de taille de la fenêtre

    return () => {
      window.removeEventListener("resize", checkDevice); // Nettoyez l'événement lors du démontage
    };
  }, [device]);

  return isDevice;
};

export default useCheckIsDevice;
