import ForgotPasswordForm from "@/auth/components/ForgotPasswordForm";
import { useAuth } from "@/auth/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const PasswordLost = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  //Si l'utilisation est déjà connecté, on le redirige vers la page program
  if (isAuthenticated && user?.isVerified) {
    navigate(`/submit/programs`);
  }
  return (
    <div className="container">
      <div className="flex flex-wrap md:w-3/4 mx-auto mb-5 mt-8">
        <div className="basis-full md:basis-1/2 md:pr-6 mb-5 md:mb-0 mx-auto">
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default PasswordLost;
