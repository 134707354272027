import {
  CollapseButton,
  File,
  Folder,
  Tree,
} from "@/components/extension/tree-view-api";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { convertStringToNumber, getProjectTypeFields } from "@/libs/utils";
import { CategoryProjectType } from "@/types";
import { Check } from "lucide-react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import slugify from "slugify";
import { useTypeProjectContext } from "../context/typeProgramContext";
import useProgram from "../hooks/useProgram";

const Treeview: FC<{
  handleChangeStep: (step: number) => void;
  currentStep: number;
}> = ({ handleChangeStep, currentStep }) => {
  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const { programData, updateProgramData } = useProgram();
  const program = programData[idParams];
  const { typesProject } = useTypeProjectContext();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project") || "";
  const project = program?.projects?.find(p => p.id == projectId) || {}
  const stepStates =
    project?.stepStates === undefined
      ? []
      : project?.stepStates;
  const navigate = useNavigate();
  const paramsSlug = {
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  };
  const lastKey =
    currentStep == 1
      ? "3"
      : program?.typesProject?.length > 0
      ? ((parseInt(projectId)) + 10).toString()
      : "5";

  const {t} = useTranslation("submit", {
    keyPrefix: "tree"
  })

  const elements = [
    {
      id: "1",
      isSelectable: true,
      name: "Mes programmes",
      children: [
        {
          id: "2",
          isSelectable: true,
          name: "program.programName",
          children: [
            {
              id: "3",
              isSelectable: true,
              name: "Mes projets",
              children: program?.typesProject
                ?.map((slug: string, key: number) => {
                  const item = typesProject?.find(
                    (item: CategoryProjectType) =>
                      slugify(item.name, paramsSlug) === slug
                  );
                  return (
                    item && {
                      id: (key + 10).toString(),
                      isSelectable: true,
                      name: item.name,
                      children: [
                        {
                          id: "40",
                          isSelectable: true,
                          name: "Détails du projet",
                        },
                        {
                          id: "41",
                          isSelectable: true,
                          name: "Échéancier du projet",
                        },
                        {
                          id: "42",
                          isSelectable: true,
                          name: "Financements",
                        },
                        {
                          id: "43",
                          isSelectable: true,
                          name: "Autres informations",
                        },
                        {
                          id: "44",
                          isSelectable: true,
                          name: "Parcelle et localisation",
                        },
                        {
                          id: "45",
                          isSelectable: true,
                          name: "Photos",
                        },
                      ],
                    }
                  );
                })
                .filter(Boolean), // On filtre pour ne garder que les éléments valides
            },
          ],
        },
      ],
    },
  ];
  if (!typesProject) {
    return <LoadingSpinner />; // Afficher un message de chargement si les données ne sont pas encore disponibles
  }
  return (
    <Tree
      className="rounded-[20px] bg-[#f4f4f4] overflow-hidden p-4"
      initialSelectedId={lastKey}
      elements={elements}
    >
      <Folder element={t("Mes programmes")} value="1">
        <Folder value="2" element={program?.programName || "Mon projet"}>
          <Folder value="3" element={t("Mes projets")}>
            {program?.projects?.map((p, key: number) => {
              const item = typesProject?.find(
                (item: CategoryProjectType) =>
                  slugify(item.name, paramsSlug) === p.type
              );

              const fields = getProjectTypeFields(typesProject, project.type);

              const fieldsInStep = fields.filter(
                (field) =>
                  field === "surface" ||
                  field === "trees" ||
                  field === "sau" ||
                  field === "linear" ||
                  field === "peopleInvolved" ||
                  field === "protectedSpecies" ||
                  field === "plantedSpecies"
              );

              return (
                item && (
                  <Folder
                    key={p.id}
                    value={(p.id + 10).toString()}
                    element={p.name}
                    className={`w-full text-left items-start`}
                    projectId={p.id}
                  >
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Détails du projet")}
                        onClick={() => {
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=2`
                          );
                        }}
                        currentStep={currentStep}
                        step={2}
                        state={
                          stepStates[2] === undefined ? false : stepStates[2]
                        }
                        stepLabel="1"
                      />
                    </File>
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Échéancier du projet")}
                        onClick={() => {
                          if (stepStates[2] === undefined || !stepStates[2])
                            return;
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=3`
                          );
                        }}
                        currentStep={currentStep}
                        step={3}
                        state={
                          stepStates[3] === undefined ? false : stepStates[3]
                        }
                        stepLabel="2"
                      />
                    </File>
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Financements")}
                        onClick={() => {
                          if (
                            stepStates[2] === undefined ||
                            !stepStates[2] ||
                            stepStates[3] === undefined ||
                            !stepStates[3]
                          )
                            return;
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=4`
                          );
                        }}
                        currentStep={currentStep}
                        step={4}
                        state={
                          stepStates[4] === undefined ? false : stepStates[4]
                        }
                        stepLabel="3"
                      />
                    </File>
                    {fieldsInStep.length > 0 && (
                      <File
                        value={(p.id + 40).toString()}
                        fileIcon=""
                        style={
                          fieldsInStep.length === 0
                            ? { display: "none", height: 0 }
                            : {}
                        }
                      >
                        <StepMenu
                          title={t("Autres informations")}
                          onClick={() => {
                            if (
                              stepStates[2] === undefined ||
                              !stepStates[2] ||
                              stepStates[3] === undefined ||
                              !stepStates[3] ||
                              stepStates[4] === undefined ||
                              !stepStates[4]
                            )
                              return;
                            navigate(
                              `/submit/program/${idParams}?project=${p.id}&step=5`
                            );
                          }}
                          currentStep={currentStep}
                          step={5}
                          state={
                            stepStates[5] === undefined ? false : stepStates[5]
                          }
                          stepLabel={"4"}
                        />
                      </File>
                    )}
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Parcelle et localisation")}
                        onClick={() => {
                          if (
                            stepStates[2] === undefined ||
                            !stepStates[2] ||
                            stepStates[3] === undefined ||
                            !stepStates[3] ||
                            stepStates[4] === undefined ||
                            !stepStates[4] ||
                            stepStates[5] === undefined ||
                            !stepStates[5]
                          )
                            return;
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=6`
                          );
                        }}
                        currentStep={currentStep}
                        step={6}
                        state={
                          stepStates[6] === undefined ? false : stepStates[6]
                        }
                        stepLabel={fieldsInStep.length === 0 ? "4" : "5"}
                      />
                    </File>
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Pièces jointes")}
                        onClick={() => {
                          if (
                            stepStates[2] === undefined ||
                            !stepStates[2] ||
                            stepStates[3] === undefined ||
                            !stepStates[3] ||
                            stepStates[4] === undefined ||
                            !stepStates[4] ||
                            stepStates[5] === undefined ||
                            !stepStates[5] ||
                            stepStates[6] === undefined ||
                            !stepStates[6]
                          )
                            return;
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=7`
                          );
                        }}
                        currentStep={currentStep}
                        step={7}
                        state={
                          stepStates[7] === undefined ? false : stepStates[7]
                        }
                        stepLabel={fieldsInStep.length === 0 ? "5" : "6"}
                      />
                    </File>
                    <File value={(p.id + 40).toString()} fileIcon="">
                      <StepMenu
                        title={t("Photos")}
                        onClick={() => {
                          if (
                            stepStates[2] === undefined ||
                            !stepStates[2] ||
                            stepStates[3] === undefined ||
                            !stepStates[3] ||
                            stepStates[4] === undefined ||
                            !stepStates[4] ||
                            stepStates[5] === undefined ||
                            !stepStates[5] ||
                            stepStates[6] === undefined ||
                            !stepStates[6] ||
                            stepStates[7] === undefined ||
                            !stepStates[7]
                          )
                            return;
                          navigate(
                            `/submit/program/${idParams}?project=${p.id}&step=8`
                          );
                        }}
                        currentStep={currentStep}
                        step={8}
                        state={
                          stepStates[8] === undefined ? false : stepStates[8]
                        }
                        stepLabel={fieldsInStep.length === 0 ? "6" : "7"}
                      />
                    </File>
                  </Folder>
                )
              );
            })}
          </Folder>
        </Folder>
      </Folder>
      <CollapseButton elements={elements} />
    </Tree>
  );
};

const StepMenu: FC<
  {
    title: string;
    currentStep: number;
    step: number;
    state: boolean;
    stepLabel?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, currentStep, step, state, stepLabel, ...props }) => {
  return (
    <div className="basis-full relative " {...props}>
      <div className={`py-1 rounded-md pl-0 flex pr-6  text-left items-start`}>
        <p className="flex items-center text-[13px] mb-0">
          {state ? (
            <span
              className={`flex mr-2 items-center justify-center w-6 h-6 ${
                currentStep >= step
                  ? "bg-primary text-background"
                  : "bg-primary/15"
              } rounded-full`}
            >
              <Check size={15} />{" "}
            </span>
          ) : (
            <span
              className={`text-[13px] flex mr-2 items-center justify-center w-6 h-6 ${
                currentStep == step
                  ? "bg-primary text-background"
                  : "bg-primary/15 text-foreground"
              }  rounded-full`}
            >
              {stepLabel}
            </span>
          )}
          <span
            className={`text-left text-[13px] ${
              currentStep >= step ? "font-semibold" : ""
            } line-clamp-2`}
          >
            {title}
          </span>
        </p>
      </div>
    </div>
  );
};
export default Treeview;
