import { FC, ReactNode, useState } from "react";
import HeaderTop from "./HeaderTop";
import Leftbar from "./Leftbar";

const LayoutDashboard: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="flex flex-wrap">
      <div className={`${isOpen ? "w-[280px]" : "w-[70px]"} transition-all`}>
        <Leftbar setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
      <div className="flex-1 h-screen pl-10 ">
        <HeaderTop />

        {children}
      </div>
    </div>
  );
};

export default LayoutDashboard;
