import NotFoundPage from "@/components/errors/404";
import ErrorBoundary from "@/components/errors/ErrorBoundary";
import LayoutDashboard from "@/dashboard/components/layout/Layout";
import Auth from "@/pages/Auth";
import Dashboard from "@/pages/Dashboard";
import EmailVerification from "@/pages/EmailVerification";
import ForgotPassword from "@/pages/ForgotPassword";
import Home from "@/pages/Home";
import Program from "@/pages/Program";
import Programs from "@/pages/Programs";
import ResetPassword from "@/pages/ResetPassword";
import Layout from "@/programs/components/layout/Layout";
import { Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Layout>
              <Home />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/"
        element={
          <ErrorBoundary>
            <Layout>
              <Home />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/program/:id"
        element={
          <ErrorBoundary>
            <Layout>
              <Program />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/programs"
        element={
          <ErrorBoundary>
            <Layout>
              <Programs />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/login"
        element={
          <ErrorBoundary>
            <Layout>
              <Auth />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/register/verify"
        element={
          <ErrorBoundary>
            <Layout>
              <EmailVerification />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/forgot-password"
        element={
          <ErrorBoundary>
            <Layout>
              <ForgotPassword />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/submit/reset-password"
        element={
          <ErrorBoundary>
            <Layout>
              <ResetPassword />
            </Layout>
          </ErrorBoundary>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ErrorBoundary>
            <LayoutDashboard>
              <Dashboard />
            </LayoutDashboard>
          </ErrorBoundary>
        }
      />
      <Route
        path="*"
        element={
          <ErrorBoundary>
            <Layout>
              <NotFoundPage isPageChildren={false} />
            </Layout>
          </ErrorBoundary>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
