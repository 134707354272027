import { FormDataProjectType } from "@/types";
import { createContext, ReactNode, useEffect, useState } from "react";

export interface ProgramContextType {
  programData: { [id: number]: FormDataProjectType };
  currentId: number | null;
  setCurrentId: (id: number) => void;
  updateProgramData: (key: keyof FormDataProjectType, value: any) => void;
  updateProjectStepState: (
    id: number,
    step: number,
    state: boolean
  ) => void;
}

const ProgramContext = createContext<ProgramContextType | undefined>(undefined);

export const ProgramProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialDataProgram: { [id: number]: FormDataProjectType } = {};

  const [programData, setProgramData] = useState<{
    [id: number]: FormDataProjectType;
  }>(() => {
    const storedFormData = localStorage.getItem("programData");
    return storedFormData ? JSON.parse(storedFormData) : initialDataProgram;
  });

  const [currentId, setCurrentId] = useState<number | null>(null);

  const [editKey, setEditKey] = useState("");

  const updateProgramData = (key: keyof FormDataProjectType, value: any) => {
    setEditKey(key);
    if (currentId === null) return; // Ne pas faire de mise à jour si aucun ID n'est défini

    setProgramData((prevData) => ({
      ...prevData,
      [currentId]: {
        ...prevData[currentId],
        [key]: value,
      },
    }));
  };

  const updateProjectStepState = (id: number, step: number, state: boolean) => {
    if (currentId === null) return; // Ne pas faire de mise à jour si aucun ID n'est défini

    setProgramData((prevData) => ({
      ...prevData,
      [currentId]: {
        ...prevData[currentId],
        projects: [
          ...prevData[currentId].projects.map((p) => {
            if (p.id === id) {
              return {
                ...p,
                stepStates: {
                  ...p.stepStates,
                  [step]: state,
                }
              }
            }

            return p
          })
        ],
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("programData", JSON.stringify(programData));
  }, [programData, editKey]);

  useEffect(() => {
    if (currentId === null) return; // Ne pas faire de mise à jour si aucun ID n'est défini

    setProgramData({
      [currentId]: {
        programName: "",
        projects: [],
        typesProject: [],
      },
    });
  }, [currentId]);

  return (
    <ProgramContext.Provider
      value={{ programData, updateProgramData, updateProjectStepState, currentId, setCurrentId }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export default ProgramContext;
