import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { signIn, register, loading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const {t} = useTranslation("auth", {
    keyPrefix: "register"
  });

  const formSchema = z
  .object({
    lastname: z.string().min(2, {
      message: t("Le nom de famille doit contenir au moins 2 caractères."),
    }),
    firstname: z.string().min(2, {
      message: t("Le prénom doit contenir au moins 2 caractères."),
    }),
    structureName: z.string().min(2, {
      message: t("Le nom de la structure doit contenir au moins 2 caractères."),
    }),
    email: z
      .string()
      .min(5, {
        message: t("L'adresse e-mail doit contenir au moins 5 caractères."),
      })
      .regex(emailRegex, {
        message: t("L'adresse e-mail n'est pas valide."),
      }),
    password: z
      .string()
      .min(8, {
        message: t("Le mot de passe doit contenir au moins 8 caractères."),
      })
      .regex(passwordRegex, {
        message:
          t("Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre."),
      }),
    confirmPassword: z
      .string()
      .min(8, {
        message:
          t("La confirmation du mot de passe doit contenir au moins 8 caractères."),
      })
      .regex(passwordRegex, {
        message:
          t("La confirmation du mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre."),
      }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: t("Les mots de passe ne correspondent pas."),
  });

  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lastname: "",
      structureName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { setError } = form;

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { success, errors } = await register(
      values.firstname,
      values.lastname,
      values.structureName,
      values.email,
      values.password,
      values.confirmPassword
    );
    
    if (success) {
      await signIn(values.email, values.password);

      navigate(`/submit/register/verify`);
    } else {
      if(errors) {
        errors.forEach((error) => {
          setError(error[0], {
            type: "server",
            message: error[1],
          });
        });
      } else {
        // Déclenche une erreur pour le champ mot de passe
        setError("password", {
          type: "server",
          message: t("Une erreur est survenue, veuillez reessayer."),
        });
      }
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Créez votre compte")}</CardTitle>
        <CardDescription>
          {t("Pour soumettre votre projet et bénéficier du soutien de Verdeo, veuillez créer un compte. Cela vous permettra de suivre vos projets, recevoir des mises à jour et accéder aux outils de traçabilité gratuits.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Nom")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("Nom")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

<FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Prénom")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("Prénom")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="structureName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Nom de la structure")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("Nom de la structure")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Mail")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("Mail")} {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Mot de passe")}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder={t("Mot de passe")}
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                      />
                      <button onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword)
                      }} className="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 flex items-center justify-center">
                        {!showPassword ? <EyeOff /> : <Eye />}
                      </button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("Confirmation mot de passe")}</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        placeholder={t("Confirmation mot de passe")}
                        {...field}
                        type={showPasswordConfirm ? 'text' : 'password'}
                      />
                      <button onClick={(e) => {
                        e.preventDefault();
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }} className="absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5 flex items-center justify-center">
                        {!showPasswordConfirm ? <EyeOff /> : <Eye />}
                      </button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button disabled={loading} type="submit" variant={"default"} className="w-full">
              {loading ? "..." : t("S'inscrire")}
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default RegistrationForm;
