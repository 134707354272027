import { Button } from "@/components/ui/button";
import { User2 } from "lucide-react";

const HeaderTop = () => {
  return (
    <div className="flex flex-wrap justify-between py-4">
      <Button variant={"roundedGray"}>Ajouter un programme</Button>
      <div className="flex items-center gap-5">
        <Button variant={"roundedOrange"}>Carte des projets</Button>
        <div
          className="relative w-12 cursor-pointer"
          onClick={() => alert("je suis cliqué")}
        >
          <User2 size={35} />
          <span className="w-4 h-4 flex items-center justify-center rounded-full bg-destructive text-[10px] absolute right-4 top-1 border-2 border-background text-background">
            3
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
