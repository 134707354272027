import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import { toast } from "@/components/ui/use-toast";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  checkCodeForgotPassword,
  resendCodeConfirmation,
} from "../services/authService";

const CodeResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const mail = searchParams.get("mail");
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!mail) {
      navigate("/submit/forgot-password");
    }
  }, [searchParams]);

  const { loading } = useAuth();
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [d3, setD3] = useState("");
  const [d4, setD4] = useState("");
  const [d5, setD5] = useState("");
  const [d6, setD6] = useState("");

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const [disabled, setDisabled] = useState(false);
  const [disabledResend, setDisabledResend] = useState(false);

  const {t} = useTranslation("auth", {
    keyPrefix: "login",
  });

  async function setInput(input: string, value: string) {
    switch (input) {
      case "d1":
        setD1(value);
        if (value !== "") ref2.current?.focus();
        break;
      case "d2":
        setD2(value);
        if (value !== "") ref3.current?.focus();
        break;
      case "d3":
        setD3(value);
        if (value !== "") ref4.current?.focus();
        break;
      case "d4":
        setD4(value);
        if (value !== "") ref5.current?.focus();
        break;
      case "d5":
        setD5(value);
        if (value !== "") ref6.current?.focus();
        break;
      case "d6":
        setD6(value);
        break;
    }
  }

  useEffect(() => {
    if (
      d1 !== "" &&
      d2 !== "" &&
      d3 !== "" &&
      d4 !== "" &&
      d5 !== "" &&
      d6 !== ""
    ) {
      setDisabled(true);
      checkCodeForgotPassword({
        code: d1 + d2 + d3 + d4 + d5 + d6,
        email: mail,
      }).then((res) => {
        if (res.success) {
          toast({
            title: t("Email verifié"),
          });

          setTimeout(() => {
            navigate(
              `/submit/reset-password?mail=${mail}&code=${
                d1 + d2 + d3 + d4 + d5 + d6
              }`
            );
          }, 400);
        } else {
          toast({
            title: t("Code invalide"),
          });

          setDisabled(false);
        }
      });
    } else {
      const code = d1 + d2 + d3 + d4 + d5 + d6;

      if (
        code.length === 6 &&
        (d1 === "" ||
          d2 === "" ||
          d3 === "" ||
          d4 === "" ||
          d5 === "" ||
          d6 === "")
      ) {
        setD1(code[0]);
        setD2(code[1]);
        setD3(code[2]);
        setD4(code[3]);
        setD5(code[4]);
        setD6(code[5]);
      }
    }
  }, [d1, d2, d3, d4, d5, d6]);

  const resendCode = async () => {
    setDisabledResend(true);

    resendCodeConfirmation().then((res) => {
      if (res.success) {
        toast({
          title: "Code renvoyé",
        });
      } else {
        toast({
          title: "Code non renvoyé",
        });
      }
      setDisabledResend(false);
    });
  };

  return (
    <Card className="mb-6 mt-10 p-6">
      <CardHeader>
        <CardTitle className="text-center">
          {t("Confirmez votre adresse e-mail")}
        </CardTitle>
        <CardDescription className="mx-auto w-[95%] text-center lg:w-2/4">
        {t("Nous venons de vous envoyer un e-mail avec un code de confirmation. Veuillez saisir ce code dans le champ ci-dessous pour verifier que l'email est correct. Si vous n'avez pas reçu l'e-mail, vérifiez vos spams ou cliquez sur 'Renvoyer le code'.")}
        </CardDescription>
      </CardHeader>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CardContent>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="mt-6 w-full space-y-8 text-center"
          >
            <div className="flex justify-center space-x-2 lg:space-x-4">
              <div>
                <Input
                  disabled={disabled}
                  ref={ref1}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  value={d1}
                  className="size-8 p-0 md:size-14"
                  onChange={(e) => setInput("d1", e.target.value)}
                />
              </div>
              <div>
                <Input
                  disabled={disabled}
                  ref={ref2}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  className="size-8 p-0 md:size-14"
                  value={d2}
                  onChange={(e) => setInput("d2", e.target.value)}
                />
              </div>
              <div>
                <Input
                  disabled={disabled}
                  ref={ref3}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  className="size-8 p-0 md:size-14"
                  value={d3}
                  onChange={(e) => setInput("d3", e.target.value)}
                />
              </div>
              <div>
                <Input
                  disabled={disabled}
                  ref={ref4}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  className="size-8 p-0 md:size-14"
                  value={d4}
                  onChange={(e) => setInput("d4", e.target.value)}
                />
              </div>
              <div>
                <Input
                  disabled={disabled}
                  ref={ref5}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  className="size-8 p-0 md:size-14"
                  value={d5}
                  onChange={(e) => setInput("d5", e.target.value)}
                />
              </div>
              <div>
                <Input
                  disabled={disabled}
                  ref={ref6}
                  type="text"
                  style={{
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                  className="size-8 p-0 md:size-14"
                  value={d6}
                  onChange={(e) => setInput("d6", e.target.value)}
                />
              </div>
            </div>

            <Button
              disabled={disabledResend}
              type="button"
              variant={"default"}
              className="mx-auto"
              onClick={resendCode}
            >
              {t("Renvoyer le code")}
            </Button>
          </form>
        </CardContent>
      )}
    </Card>
  );
};

export default CodeResetPasswordForm;
